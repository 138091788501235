import template from './tickets.html';
import style from './tickets.less';
import seatsTemplate from './seats/seats.html';

export default angular.module('eventix.shop.tickets', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.tickets', {
            url: '/tickets?eventId',
            views: {
                'shop': {
                    controller: 'TicketsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('TicketsController', function(UIMessages, shop, dates, futureDates, order, OrderManager, collapses, $state,
        $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce,
        $analytics, FB, $timeout, store, $filter, $location, SeatsIO, ShopAnalytics, AskAPI, Collapse) {
        var vm = this;
        vm.seatSelection = {
            templateUrl: seatsTemplate,
            open: false
        };

        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;
        vm.eventFilter = $state.params.eventId;
        vm.noTickets = true;
        vm.fourStepWizard = false;
        vm.limitToTickets = [];
        vm.collapses = collapses;
        vm.enabledCollapses = {};
        vm.ticketsAvailable = false;
        vm.noTicketsAvailable = false;

        function init(order){
            vm.order = order;
            vm.products = order.products;

            let tickets = order.tickets;
            let events = order.events;

            // we have a filter / received new tickets, only show from last request because there relevant for current date
            if(vm.limitToTickets.length > 0 || vm.noTicketsAvailable){
                let newTickets = {};
                _.forEach(tickets, function(tickets,event){
                    let filteredTickets = _.filter(tickets, function(ticket){
                        return (vm.limitToTickets).indexOf(ticket.guid) !== -1;
                    });

                    if(filteredTickets.length > 0){
                        newTickets[event] = filteredTickets;
                    }
                });

                let filteredEvents = _.filter(events, function(event){
                    return (_.keys(newTickets)).indexOf(event.guid) !== -1;
                });

                let newEvents = {};
                _.forEach(filteredEvents, function(event){
                    event.hasOtherStart = moment(vm.pickedDate).format('YYYY-MM-DD') !== moment(event.start_raw).format('YYYY-MM-DD');
                    newEvents[event.guid] = event;
                });

                vm.tickets = newTickets;
                vm.events = newEvents;
            }else{
                vm.tickets = order.tickets;
                vm.events = order.events;
            }

            let allTicketGuids = [];
            order.recalculateAvailableTickets();
            _.forEach(vm.tickets, function(tickets, event){
                vm.tickets[event] = _.orderBy(tickets, ['name'], ['asc']);
                _.forEach(tickets, function(ticket){
                    if(ticket.available) {
                        allTicketGuids.push(ticket.guid);
                    }
                });
            })
            if(allTicketGuids.length === 0){
                vm.ticketsAvailable = false;
            }
            else{
                vm.ticketsAvailable = true;
            }

            _.forEach(vm.collapses, function(collapse){
                collapse.enabled = _.intersection(allTicketGuids, collapse.ticket_guids).length > 0;
            })



            vm.busy = false;
        }


        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        vm.initDating = true;
        vm.dates = futureDates;

        let today = moment();
        let minStart = moment.max(moment(vm.dates[0]), moment());
        let minDate;
        if(today.isAfter(minStart)){
            minDate = today;
        }
        else{
            minDate = minStart;
        }
        vm.pickedDate = minDate;
        init(order);

        function isDateDisabled(obj){
            if(obj.mode !== 'day')
                return false;

            let date = obj.date;
            let dateString = date.getFullYear() + "-" + ('0' + (date.getMonth()+1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
            return !((vm.dates).indexOf(dateString) !== -1);
        }

        vm.dateOptions = {
            initDate: minDate.toDate(),
            minDate: minDate.toDate(),
            maxDate: moment(vm.dates[vm.dates.length - 1]),
            showWeeks: true,
            startingDay: 1,
            maxMode: 'day',
            dateDisabled: isDateDisabled
        };

        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        // TODO This, check in shop
        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        vm.changeDate = changeDate;
        function changeDate(){
            vm.busy = true;
            if(vm.initDating){
                vm.initDating = false;
                vm.busy = false;
                return;
            }
            let url = '/' + vm.shop.guid + '/data?start='+moment(vm.pickedDate).format('YYYY-MM-DD') + "&end=" + moment(vm.pickedDate).add(1, 'days').format('YYYY-MM-DD');
            return $http.get(url)
                .then(response => {
                    vm.limitToTickets = [];
                    if(response.data.tickets.length === 0){
                        vm.noTicketsAvailable = true;
                    }
                    else{
                        vm.noTicketsAvailable = false;
                    }
                    _.forEach(response.data.tickets, function(ticket){
                        vm.limitToTickets.push(ticket.guid);
                    });

                    AskAPI.restore(response).then(results => {
                        OrderManager.append().then(order => {
                        }).then(resp => {

                            if(vm.fourStepWizard && vm.eventFilter)
                                ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
                            else if(!vm.fourStepWizard)
                                ShopAnalytics.impression();

                            // need to reinit collapses
                            return Collapse.query().then(collapses => {
                                vm.collapses = $filter('sortByPredecessor')(collapses);
                                init(OrderManager.instance);
                            });
                        });
                    });

                });
        }
        $scope.$watch('vm.pickedDate', vm.changeDate, true);

        /**
         * Close seat selection dialog
         */
        vm.closeSeatSelection = function() {
            vm.seatSelection.open = false;
        };

        vm.openSeatSelection = function(ticket, eventId) {
            angular.element('body')[0].scrollTop = 0;
            window.scrollTo(0, 0);
            SeatsIO.open(ticket, eventId).then(() => vm.seatSelection.open = true);
        };

        vm.setLocale = code => {
            $translate.use(code);
        };

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
            if (_.flatten(_.values(order.reservations)).length < 1)
                UIMessages.push('common.shop.order.empty');
            else {
                // let showOptionals = false;
                // _.forEach(vm.order.reservations, function(reservations, ticketGuid){
                //     if(vm.order.showTicketReservationForm(ticketGuid)) // has optionals
                //         showOptionals = true;
                // });
                // if(showOptionals)
                //     $state.go('eventix.shop.ticketOptions', {shopId: shop.guid, eventId: $state.params.eventId});
                // else
                $state.go('eventix.shop.personalDetails', {shopId: shop.guid, eventId: $state.params.eventId});
            }
        };

        vm.reserve = function(ticketGuid, eventId){
            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.toId = function(string){
            return string.replace(/\W/g, '');
        };
    }).directive('reservationSummaryCustom', function(Locales, Currencies) {
        return {
            restrict: 'E',
            replace: true,
            template: `<div class="ticket-cost col-xs-3">
    <span class="sum" ng-show="(priceQuantities | lodash:'keys').length <= 2">
        <span class="other-price" ng-repeat="(price, quantity) in priceQuantities" ng-hide="price == currentPrice">
            {{ quantity }} &times;
            <exchange-currency convert-to="currencies.selected" locale="locales.selected"
                amount="price" convert-from="shop.currency" ng-class="{'text-danger': price != ticket.minimumPrice && coupon, 'strike-out': quantity == 0 }"></exchange-currency>
                <br/>
        </span>
        {{ priceQuantities[currentPrice] }} &times;
        <exchange-currency  convert-to="currencies.selected" locale="locales.selected"
            amount="currentPrice" convert-from="shop.currency" ng-class="{'text-danger': currentPrice != ticket.minimumPrice }"></exchange-currency>
    </span>
    <span class="sum" ng-show="(priceQuantities | lodash:'keys').length > 2">
        {{ priceQuantities[currentPrice] }} &times;
        <exchange-currency convert-to="currencies.selected" locale="locales.selected"
            amount="currentPrice" convert-from="shop.currency" ng-class="{'text-danger': currentPrice != ticket.minimumPrice }"></exchange-currency>
        <span class="other-price">
            {{ reservations.length - priceQuantities[currentPrice] }} &times; &hellip;
        </span>
    </span>
<!--    <span class="total">-->
<!--        <exchange-currency convert-to="currencies.selected" locale="locales.selected"-->
<!--            amount="total" convert-from="shop.currency"></exchange-currency>-->
<!--    </span>-->
</div>`,
            scope: {
                ticket: '=',
                reservations: '=',
                shop: '=',
                coupon: '='
            },
            link: function(scope, el, attr) {
                scope.currentPrice = scope.ticket.discountPrice || scope.ticket.minimumPrice;
                scope.priceQuantities = {};
                scope.priceQuantities[scope.currentPrice] = 0;
                scope.total = 0;
                scope.currentPrice = 0;

                scope.currencies = Currencies;
                scope.locales = Locales;

                scope.$watch('ticket', ticket => {
                    if(!ticket) return;
                    scope.currentPrice = (typeof ticket.discountPrice === 'undefined' ? ticket.minimumPrice : ticket.discountPrice);
                    if(!_.has(scope.priceQuantities, scope.currentPrice))
                        scope.priceQuantities[scope.currentPrice] = 0;
                }, true);

                scope.$watch('reservations', reservations => {
                    if(scope.ticket)
                        scope.currentPrice = scope.ticket.discountPrice || scope.ticket.minimumPrice;
                    scope.priceQuantities = {};
                    scope.priceQuantities[scope.currentPrice] = 0;
                    scope.total = 0;

                    if(!reservations || reservations.length === 0)
                        return;

                    let substractAmountForDisplay = 0;
                    if(scope.currentPrice !== 0 && !scope.ticket.include_service_cost_in_price){
                        let varying = scope.currentPrice * scope.ticket.service_cost_percentage;
                        substractAmountForDisplay = scope.ticket.service_cost + varying;
                    }

                    let sorted = _.groupBy(reservations, r => Math.round(r.price - substractAmountForDisplay));
                    let prices = _.keys(sorted);
                    _.forEach(sorted, (reservations, price) => {
                        if(!_.has(scope.priceQuantities, 'price'))
                            scope.priceQuantities[price] = 0;
                        scope.priceQuantities[price] += reservations.length;
                    });
                    scope.total = _.sumBy(reservations, r => Math.round(r.price - substractAmountForDisplay));
                }, true);
            }
        };
    }).name;
