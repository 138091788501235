import template from './shop.html';
import Errors from './error/error.js';
import Tickets from './tickets/tickets.js';
import Header from './header/header.js';
import HeaderTemplate from './header/header.html';
import personalDetails from './personalDetails/personalDetails.js';
import ticketOptions from './ticketOptions/ticketOptions.js';
import angulartics from 'angulartics';
import style from './shop.less';
import Summary from './summary/summary.js';

export default angular.module('eventix.shop',[ Tickets, Header, angulartics, Errors, personalDetails, Summary, ticketOptions])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop',{
            url: '/:shopId?highlight',
            params: {
                highlight: { inherit: false, dynamic: true }
            },
            resolve: /*@ngInject*/{
                dates: function($http, $transition$) {
                    let shopId = $transition$.params().shopId;
                    let url = '/' + shopId + '/dates';

                    var getBetweenDates = function(startDate, endDate) {
                        var dates = [],
                            currentDate = startDate,
                            addDays = function(days) {
                                var date = new Date(this.valueOf());
                                date.setDate(date.getDate() + days);
                                return date;
                            };
                        while (currentDate <= endDate) {
                            dates.push(new Date(currentDate));
                            currentDate = addDays.call(currentDate, 1);
                        }
                        return dates;
                    };

                    return $http.get(url)
                        .then(response => {
                            let dates = [];
                            _.forEach(response.data, function(entry){
                                _.forEach(getBetweenDates(Date.parse(entry.start), Date.parse(entry.end)), function(date){
                                    let dateString = date.getFullYear() + "-" + ('0' + (date.getMonth()+1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
                                    if(dates.indexOf(dateString) == -1){
                                        dates.push(dateString);
                                    }
                                });
                            })
                            dates = _.orderBy(dates);
                            return dates;
                        }).catch(function(error) {
                            return [];
                        });
                },
                futureDates: function(dates){
                    let today = moment();
                    let todayString = today.format('YYYY-MM-DD');
                    let futureDates = [];
                    _.forEach(dates, function(value, key){
                        if(value >= todayString){
                            futureDates.push(value);
                        }
                    })
                    return futureDates;
                },
                shop: function($http, $transition$, AskAPI, Shop, Rollbar, frameInfo, $state, $log,
                    Currencies, $q, dates, futureDates) {
                    if(frameInfo.isFramed && frameInfo.width < 330) {
                        $transition$.abort();
                        $state.go('eventix.breakFrame', { shopId: $transition$.params().shopId });
                        return $q.reject('Going to breakFrame');
                    }
                    let shopId = $transition$.params().shopId;
                    let minStart = moment.max(moment(futureDates[0]), moment());
                    let url = '/' + shopId + '/data?start=' + minStart.format('YYYY-MM-DD') + '&end=' + minStart.add(1, 'days').format('YYYY-MM-DD');
                    return $http.get(url)
                        .then(response => {
                            return AskAPI.restore(response);
                        }).then(() => {
                            let shop = Shop.cached.first();
                            Currencies.use(shop.currency);
                            return shop;
                        });
                },
                order: function(shop, OrderManager, frameInfo, $q) {
                    return OrderManager.init();
                },
                metaData: function(MetaData, order) {
                    return MetaData.query();
                },
                products: function(Product, order) {
                    return Product.query();
                },
                tickets: function(Ticket, order, $q, $filter) {
                    return Ticket.query().then(tickets => {
                        return $filter('sortByPredecessor')(tickets);
                    });
                },
                paymentMethods: function(PaymentMethod, shop, $filter) {
                    return shop.$queryPaymentMethod();
                },
                collapses: function(Collapse, order, $q, $filter) {
                    return Collapse.query().then(collapses => {
                        return $filter('sortByPredecessor')(collapses);
                    });
                },
                globalProducts: function (Product, order) {
                    return Product.query().then(products => {
                        return products.filter(p => p.isGlobalProduct)
                    })
                },
            },
            views: {
                header: {
                    controller: 'HeaderController',
                    controllerAs: 'vm',
                    templateUrl: HeaderTemplate
                },
                main: {
                    controller: 'ShopFrontendController',
                    controllerAs: 'vm',
                    templateUrl: template
                }
            }
        });
    })
    .run(function($rootScope, stateChangeErrorService, $state, UIMessages, $transitions, Highlighter, $q) {
        $rootScope.$on('APIMessage', function(event, status, message) {
            if(status >= 400) {
                return UIMessages.push({
                    type: 'danger',
                    message: message
                });
            }
            return UIMessages.push(message);
        });

        $transitions.onSuccess({}, function($trans) {
            let params = $trans.params();
            if(params.highlight)
                Highlighter.mark(params.highlight);
        });
        $transitions.onError({}, function(trans) {
            let fromState = trans.from();
            let toState = trans.to();
            let params = trans.params();
            if(fromState.name === 'eventix.shoperror' || toState.name === 'eventix.shoperror')
                return $q.resolve();
            let error = trans.error();
            // SUPERSEDED = 2, ABORTED = 3, INVALID = 4, IGNORED = 5, ERROR = 6
            if(error.type === 5 || error.type === 2)
                return $q.resolve();
            stateChangeErrorService(toState, trans.params(), _.get(error, 'detail'));
            return $state.go('eventix.shoperror', { shop: params.shopId });
        });
    })
    .controller('ShopFrontendController', function(UIMessages, $state, shop, order, SeatsIO,
        GoogleTagManager, Currencies, VisitorData, beforeUnload, $rootScope, paymentMethods, StripeSDK, frameInfo){
        var vm = this;
        vm.messages = UIMessages;
        vm.order = order;
        vm.shop = shop;
        if(shop.seats_public_key && shop.seats_public_key.length)
            SeatsIO.load();
        Currencies.use(vm.shop.currency);
        vm.frameInfo = frameInfo;

        beforeUnload(function() {
            order.releaseAll();
            $rootScope.$digest();
        });

        if(shop.google_tag)
            GoogleTagManager.addContainer(shop.google_tag);
        if($state.is('eventix.shop'))
            $state.go('eventix.shop.tickets', { shopId: shop.guid }, { location: 'replace' });

        let stripeMethod = _.find(paymentMethods, p => p.isStripe());
        if(stripeMethod)
            StripeSDK.get(stripeMethod.stripe_public_key);
    }).name;
